@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@500;600;700&display=swap');

body, html {
  background-color: #051529;
}

.swal2-title {
  color: #F5F7FA !important;
}

.swal2-confirm {
  background-color: #035388 !important;
}